<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'management-dashboard' }" class="root">
        Dashboard
      </router-link>
      /

      <span class="child"> Fine Collected </span> </v-row
    ><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <template>
                <download-excel
                  :data="fineData"
                  :fields="all_org"
                  worksheet="Books Data"
                  name="Books Data.xls"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        size="40"
                        left
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-excel</v-icon
                      >
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
            <template>
              <div class="text-center" v-if="circular">
                <v-progress-linear indeterminate height="25" color="#393e46">
                  <strong style="color:#FFF;">Loading...</strong>
                </v-progress-linear>
              </div>
            </template>
            <v-data-table :headers="headers" :items="fineData" :search="search">
              <template v-slot:item.fine="{ item }">
                <v-icon>mdi-currency-inr</v-icon
                ><span style="font-size:18px">{{ item.fine }}</span>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </div>
    </section>
    <!-- /. section -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    circular: false,
    fineData: [],
    orgbookdetails: [],
    loading: true,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    search_book: "",
    org_name: null,
    dialog_details: false,
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Orgnization", value: "orgName" },
      { text: "Fine collected", value: "fine" },
    ],
    all_org: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Total Fine in Rs.": "fine",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.circular=true
      axios
        .post("/ManagementAdmin/getAllOrgFineData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.fineData = res.data.fineData;
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
</style>
